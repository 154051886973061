import React from 'react'
import { Link, graphql } from 'gatsby'
import Seo from '../components/seo'
import Header from '../components/header'
import Img from "gatsby-image"
import BackgroundImage from 'gatsby-background-image'
import Footer from '../components/footer'
import Copyright from '../components/copyright'
import NavMobile from "../components/nav_mobile"

export default ({ data }) => {
    const entry = data.markdownRemark
    return (
        <div className="page">
            <NavMobile />
            <Seo title={entry.frontmatter.page_title} description={entry.frontmatter.meta_description} image="" pathname={entry.fields.slug} />
            <Header />
            <BackgroundImage className="layout" fluid={entry.frontmatter.hero.image.childImageSharp.fluid} backgroundColor={`#ffffff`}>
                <div className="layout home__overlay">
                    <div className="layout__container title__144">
                        <div className="layout__title home center">
                            <h1>{entry.frontmatter.hero.heading}</h1>
                            <p>{entry.frontmatter.hero.text}</p>
                        </div>
                    </div>
                </div>
            </BackgroundImage>
            <BackgroundImage className="layout title__72" fluid={entry.frontmatter.background.childImageSharp.fluid} backgroundColor={`#ffffff`}>
                <div className="layout__container">
                    <div className="layout__wide title white">
                        <div className="grid grid__2 grid__gap--48 align__items">
                            <figure>
                                <Img fluid={entry.frontmatter.intro.image.childImageSharp.fluid} alt={entry.frontmatter.intro.heading} />
                            </figure>
                            <div className="title__block">
                                <h2>{entry.frontmatter.intro.heading}</h2>
                                <p>{entry.frontmatter.intro.text}</p>
                                <Link to="/background-checks/" className="btn btn__margin__small btn__blue btn__small">Learn more &rsaquo;</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </BackgroundImage>

            <div className="layout blue title__96">
                <div className="layout__container">
                    <div className="layout__title title center">
                        <h4>Christmas Closure</h4>
                        <p>StaffChecks last day for the year will be <strong>5pm Monday 23rd December 2024</strong> we have skeleton staff working from <strong>Thursday 9th January</strong> to <strong>Monday 13th January</strong>, when we resume normal operations.</p>
                        <p>We would like to take this opportunity to wish you and your team  a very Merry Christmas and a Prosperous New Year.</p>
                    </div>
                </div>
            </div>

            {/*
            <div className="layout blue title__96">
                <div className="layout__container">
                    <div className="layout__title title center">
                        <h4>{entry.frontmatter.mission.heading}</h4>
                        <p>{entry.frontmatter.mission.text}</p>
                    </div>
                </div>
            </div>
            */}
            
            <div className="layout title__72 white">
                <div className="layout__container">
                    <div className="layout__wide">
                        <div>
                            <div className="title title__bottom">
                                <Link to="/background-checks/" className="right__link">See all checks &rsaquo;</Link>
                                <h3>Explore our most frequently ordered background checks</h3>
                            </div>
                            <div className="grid grid__3 grid__gap--48">
                                <div className="top__checks">
                                    <Link to="/background-checks/criminal-record/">
                                        <img src="/img/07_law_scales.svg" alt="Criminal Report" />
                                        <h2>Criminal Reports &rsaquo;</h2>
                                        <p>Reveals any criminal records for offences in New Zealand.</p>
                                        <p><strong>Average turnaround: 3 days</strong></p>
                                    </Link>
                                </div>
                                <div className="top__checks">
                                    <Link to="/background-checks/driver-licence/">
                                        <img src="/img/04_identity_card.svg" alt="Driver License &amp; Traffic History" />
                                        <h2>Driver Licenses &rsaquo;</h2>
                                        <p>Confirms driving license &amp; reveals any previous traffic complications.</p>
                                        <p><strong>Average turnaround: 24 hrs</strong></p>
                                    </Link>
                                </div>
                                <div className="top__checks">
                                    <Link to="/background-checks/drug-testing/">
                                        <img src="/img/38_medical_record.svg" alt="Drug Testing" />
                                        <h2>Drug Testing &rsaquo;</h2>
                                        <p>Detects presence of alcohol, illicit drugs, or prescription medication.</p>
                                        <p><strong>Average turnaround: 24 hrs</strong></p>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="layout title__72 dark__blue">
                <div className="layout__container">
                    <div className="layout__wide white title">
                        <div className="grid grid__2 grid__gap--48 align__items">
                            <div className="title__block__left">
                                <h2>{entry.frontmatter.checks.heading}</h2>
                                <p>{entry.frontmatter.checks.text}</p>
                                <Link to="/background-checks/" className="btn btn__margin__small btn__blue btn__small">See all checks &rsaquo;</Link>
                            </div>
                            <figure>
                                <Img fluid={entry.frontmatter.checks.image.childImageSharp.fluid} alt={entry.frontmatter.checks.heading} />
                            </figure>
                        </div>
                    </div>
                </div>
            </div>
            <BackgroundImage className="layout" fluid={entry.frontmatter.background.childImageSharp.fluid} backgroundColor={`#ffffff`}>
                <div className="layout title__72">
                    <div className="layout__container">
                        <div className="layout__wide title white">
                            <div className="grid grid__2 grid__gap--48 align__items">
                                <figure>
                                    <Img fluid={entry.frontmatter.why.image.childImageSharp.fluid} alt={entry.frontmatter.why.heading} />
                                </figure>
                                <div className="title__block">
                                    <h2>{entry.frontmatter.why.heading}</h2>
                                    <p>{entry.frontmatter.why.text}</p>
                                    <Link to="/about/" className="btn btn__margin__small btn__blue btn__small">Learn more &rsaquo;</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </BackgroundImage>
            <Footer />
            <Copyright />
        </div>
    )
}

export const query = graphql`
    query($slug: String!) {
        markdownRemark( fields: { slug: { eq: $slug } }) {
            frontmatter {
                title
                page_title
                meta_description
                background {
                    childImageSharp {
                        fluid(maxWidth: 1024) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
                hero {
                    heading
                    text
                    image {
                        childImageSharp {
                            fluid(maxWidth: 1024) {
                                ...GatsbyImageSharpFluid_withWebp
                            }
                        }
                    }
                }
                intro {
                    heading
                    text
                    image {
                        childImageSharp {
                            fluid(maxWidth: 800) {
                                ...GatsbyImageSharpFluid_withWebp
                            }
                        }
                    }
                }
                mission {
                    heading
                    text
                }
                checks {
                    heading
                    text
                    image {
                        childImageSharp {
                            fluid(maxWidth: 800) {
                                ...GatsbyImageSharpFluid_withWebp
                            }
                        }
                    }
                }
                why {
                    heading
                    text
                    image {
                        childImageSharp {
                            fluid(maxWidth: 800) {
                                ...GatsbyImageSharpFluid_withWebp
                            }
                        }
                    }
                }
            }
            fields {
                slug
            }
            html
        }
    }
`
